import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";
import Stack from "@mui/material/Stack";
import {ListItem} from "@mui/material";
import React from 'react'

export default function DogBoardingRules() {
    return <MKBox component="section" py={{xs: 3, md: 3}} sx={{ml: 4}}>
        <Container>
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
            >
                <MKTypography variant="h2" fontWeight="bold">
                    Conditions and Policies
                </MKTypography>
            </Grid>
            <Stack>
                <MKBox display="flex" alignItems="center" p={2}>

                </MKBox>
                <MKBox display="flex" alignItems="center" p={2}>
                    <Stack>
                        <MKTypography variant="h4" my={1}>
                            Conditions
                        </MKTypography>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                        A Meet&Greet should be scheduled before dog boarding. No walk-in service available.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                        20 days+ Boarding 10% off, 30 days+ Boarding 20% off, each additional dog per family 20% off .
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                        30 Boarding pass: 20% off. For repeat customers only.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                        4h Daycare: Boarding price - $10 per day, 8h Daycare: Boarding price - $5 per day.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            30 Daycare pass: 20% off. For repeat customers only.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                        A $10 per day holiday fee is charged on:

                        New Year’s Eve, New Year’s Day and weekend,
                        Martin Luther King Day and weekend, Presidents' Day and weekend,
                        Memorial Day and Weekend, Juneteenth and Weekend, Independence Day and Weekend,
                        Labor Day and Weekend,
                        Thanksgiving Day and Weekend,
                        Christmas Eve, Christmas Day and weekend.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                        Puppy under 6 month +$10 per day, Puppy under 1 year +$5 per day, unneutered Dog +$5 per day, dog poop&pee in the house +$5 per day, un-crate trained dog +$5 per day.
                        </ListItem>
                            <ListItem sx={{padding: 1, display: "list-item",}}>
                            A Rabies vaccine certificate should be provided.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            Dogs who will attack people or other dogs, bark at home frequently and have infectious diseases are not accepted.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            Contact us for a free quote if your dog is over 60 lbs.
                        </ListItem>
                    </Stack>
                </MKBox>
            </Stack>
            <MKBox display="flex" alignItems="center" p={2}>
                <Stack>
                    <MKTypography variant="h4" my={1}>
                     Payment Policies
                    </MKTypography>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                     Payment is due upon drop off. Venmo, Zelle, Paypal, Cash are accepted.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                     Daycare passes and Boarding passes are non-refundable and valid for one year.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                     20% deposit is required for holiday reservations.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item"}}>
                     Deposits are non-refundable.
                    </ListItem>
                </Stack>
            </MKBox>
        </Container>
    </MKBox>;
}