import parrotIcon from "../../../../assets/images/icon/parrot.png";
import rabbitIcon from "../../../../assets/images/icon/rabbit.png";
import guineaPigIcon from "../../../../assets/images/icon/guinea-pig.png";
import lizardIcon from "../../../../assets/images/icon/lizard2.png";

const planDescription = "2nd pet will receive 10% off every service";
export const smallAnimalPriceData = [
    {
        animal: "Parrot",
        name: "Parrot",
        unit: "oz",
        icon: parrotIcon,
        startingPrice: "$10/day",
        plans: [
            {
                name: "Standard",
                bgColor: "#ebeaf0",
                price: "$10/day",
                points: ["Bring your own cage and food", "Clean twice every week", "Photo and video update every day"]
            },
            {
                name: "Plus",
                bgColor: "#f9f0e1",
                price: "$18/day",
                points: ["1' X 2' Cage", "Clean every two days", "Photo and video update every day", "24H Camera on", "Fresh fruits and vegetables"]
            },
            {
                name: "Premium",
                bgColor: "#c0e3ec",
                price: "$30/day",
                points: ["2' X 2' Cage", "Clean every day", "Extra photo and video updates every day", "24H Camera on", "Fresh fruits and vegetables", "Blanket, toys and hiding box", "Cage-free indoor play every week", "Outdoor view every week"]
            },
        ],
        planDescription: planDescription,
        faqs: [
            {
                question: "Can you drop off and pick up?",
                answer: "Yes. We do drop off and pick up within the fairfax county. Uber will be an option if you live further away."
            },
            {
                question: "What is your operation hours?",
                answer: "We are around from 8 am to 9 pm with appointment. No walk-in service available."
            },
        ],
        categories: [
            "Parakeets", "Cockatiels", "Lovebird", "Macaws", "Africa Grey Parrots", "Green-Cheeked Conures", "Sun Conures", "Eclectus Conures", "Amazon", "Cockatoo", "Other"
        ]
    },
    {
        animal: "Guinea pig",
        name: "Guinea pig",
        icon: guineaPigIcon,
        startingPrice: "$15/day",
        plans: [
            {
                name: "Standard",
                bgColor: "#ebeaf0",
                price: "$15/day",
                points: ["Bring your own pen and food", "Clean twice every week", "Photo and video update every day"]
            },
            {
                name: "Plus",
                bgColor: "#f9f0e1",
                price: "$25/day",
                points: ["2' X 4' pen", "Clean every day", "Photo and video update every day", "24H Camera on", "Fresh fruits and vegetables"]
            },
            {
                name: "Premium",
                bgColor: "#c0e3ec",
                price: "$35/day",
                points: ["4' X 4' pen", "Clean every day", "Extra photo and video updates every day", "24H Camera on", "Fresh fruits and vegetables", "Blanket, toys and hiding box", "Outdoor play every week"]
            },
        ],
        planDescription: planDescription,
        faqs: [
            {
                question: "Can you drop off and pick up?",
                answer: "Yes. We do drop off and pick up within the fairfax county. Uber will be an option if you live further away."
            },
            {
                question: "What is your operation hours?",
                answer: "We are around from 8 am to 9 pm with appointment. No walk-in service available."
            },
        ],
        categories: [
            "abc", "cde"
        ]
    },
    {
        animal: "Bunny",
        name: "Bunny",
        icon: rabbitIcon,
        startingPrice: "$20/day",
        plans: [
            {
                name: "Standard",
                bgColor: "#ebeaf0",
                price: "$20/day",
                points: ["Bring your own pen and food", "Clean every two days", "Photo and video update every day"]
            },
            {
                name: "Plus",
                bgColor: "#f9f0e1",
                price: "$30/day",
                points: ["2' X 4' pen", "Clean every day", "Photo and video update every day", "24H Camera on", "Fresh fruits and vegetables"]
            },
            {
                name: "Premium",
                bgColor: "#c0e3ec",
                price: "$40/day",
                points: ["4' X 4' pen", "Clean every day", "Extra photo and video updates every day", "24H Camera on", "Fresh fruits and vegetables", "Blanket, toys and hiding box", "Outdoor play every week"]
            },
        ],
        planDescription: planDescription,
        faqs: [
            {
                question: "Can you drop off and pick up?",
                answer: "Yes. We do drop off and pick up within the fairfax county. Uber will be an option if you live further away."
            },
            {
                question: "What is your operation hours?",
                answer: "We are around from 8 am to 9 pm with appointment. No walk-in service available."
            },
        ],
        categories: [
            "abc", "cde"
        ]
    },
    {
        animal: "Animal",
        name: "Exotic Animals",
        unit: "oz",
        icon: lizardIcon,
        startingPrice: "$18/day",
        plans: [
            {
                name: "Standard",
                bgColor: "#ebeaf0",
                price: "$18/day",
                points: ["Bring your own tank and food", "Clean twice every week", "Photo and video update every day"]
            },
            {
                name: "Plus",
                bgColor: "#f9f0e1",
                price: "$25/day",
                points: ["1' X 2' Tank", "Clean every day", "Photo and video update every day", "24H Camera on", "Fresh fruits and vegetables"]
            }
        ],
        planDescription: planDescription,
        faqs: [
            {
                question: "Can you drop off and pick up?",
                answer: "Yes. We do drop off and pick up within the fairfax county. Uber will be an option if you live further away."
            },
            {
                question: "What is your operation hours?",
                answer: "We are around from 8 am to 9 pm with appointment. No walk-in service available."
            },
        ],
        categories: [
            "abc", "cde"
        ]
    },
];
