export const dogBoardingFaqData = [
    {
        question: "Can you drop off and pick up?",
        answer: "Yes. We do drop off and pick up within the fairfax county. Uber pet will be an option if you live further away."
    },
    {
        question: "What is your operation hours?",
        answer: "We are around from 8:30 am to 9 pm with appointment. No walk-in service available."
    },
];
