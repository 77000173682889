import XsmallDog from "../../../../assets/images/icon/small-dog-outline-chasing-balloons.png";
import smallDog from "../../../../assets/images/icon/mdog.png";
import mediumDog from "../../../../assets/images/icon/medium-dog.png";
import largeDog from "../../../../assets/images/icon/largedog.png";

const dogType = ["Border Collie", "Miniature American Shepherd", "Australian Shepherd", "German Shepherd", "Golden Retriever", "Dachshund", "Husky", "French Bulldog", "Shiba Inu", "Jack Russell", "Welsh Corgi", "Akita Inu", "Doberman Pinscher", "Labrador", "Samoyed", "Bichon", "Maltese", "Poodle", "Shih Tzu", "Yorkshire", "Sheltie", "Italian greyhound", "West highland terrier", "English Coonhound", "Cockapoo", "Cavapoo", "Goldendoodle", "Doodle", "Other"]

function buildDogPriceData(name, icon, standardPrice, plusPrice, premiumPrice) {
    return {
        animal: "Dog",
        name: name,
        unit: "lb",
        icon: icon,
        plans: [
            {
                name: "Standard",
                bgColor: "#ebeaf0",
                price: standardPrice,
                points: ["Unlimited toys", "Three times outdoor play", "Two photo and video updates", "24H Camera in yard and playroom", "20 min neighborhood walk", "A printed photo of your dog", "Treats made from real beef or chicken"]
            },
            {
                name: "Plus",
                bgColor: "#f9f0e1",
                price: plusPrice,
                points: ["Include all standard services", "30 min trail walk at park", "Extra photo and video updates", "Fresh fruits and vegetables"]
            },
            {
                name: "Premium",
                bgColor: "#c0e3ec",
                price: premiumPrice,
                points: ["Include all plus services", "Fresh quail eggs every meal", "Teeth brushing and nail care", "Two hours trail walk at national park every weekend"]
            },
        ],
        planDescription: "",
        categories: dogType
    }
}

export const dogPriceData = [
    buildDogPriceData("XSmall (<15lb)", XsmallDog, "$40/day", "$50/day", "$60/day"),
    buildDogPriceData("Small (<30lb)", smallDog, "$45/day", "$55/day", "$65/day"),
    buildDogPriceData("Medium (<45lb)", mediumDog, "$50/day", "$60/day", "$70/day"),
    buildDogPriceData("Large (<60lb)", largeDog, "$55/day", "$65/day", "$75/day"),
];